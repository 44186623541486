define([], function() {
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  var debounce = function(func, wait, immediate) {
    var timeout,
      args,
      context,
      timestamp,
      result;

    var rightnow = Date.now || function() {
      return new Date().getTime();
    };

    var later = function() {
      var last = rightnow() - timestamp;

      if (last < wait && last > 0) {
        timeout = setTimeout(later, wait - last);
      } else {
        timeout = null;
        if (!immediate) {
          result = func.apply(context, args);
          if (!timeout)
            context = args = null;
        }
      }
    };

    return function() {
      context = this;
      args = arguments;
      timestamp = rightnow();
      var callNow = immediate && !timeout;
      if (!timeout)
        timeout = setTimeout(later, wait);
      if (callNow) {
        result = func.apply(context, args);
        context = args = null;
      }

      return result;
    };
  };
  return debounce;
});
